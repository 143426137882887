import React from "react";
import PortfolioPage from "../../index";
import { eventLogos } from "../../data";

export default function EventsExhibitionPage() {
  return (
    <div>
      <PortfolioPage heading="Events & Exhibition" data={eventLogos} />
    </div>
  );
}
